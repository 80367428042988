<template>
  <div class="navigation">
    <!--we need this fake width so that the menu does not overlap on the content-->
    <div
      v-if="!alwaysExpanded"
      class="navigation__fake-width"
    />

    <!--main navigation component-->
    <v-navigation-drawer
      v-model="drawer"
      :fixed="!alwaysExpanded"
      :mini-variant.sync="mini"
      mini-variant-width="52"
      floating
      permanent
      :expand-on-hover="!alwaysExpanded"
      style="transform: none"
      width="220"
    >
      <!--logo-->
      <div class="navigation__top-fixed">
        <router-link
          to="/"
          class="navigation__logo"
          id="navigation-logo"
        >
          <v-img
            src="/img/logo-fire.svg"
            contain
            :height="21"
            :max-width="16"
          />
          <v-img
            v-if="!mini || alwaysExpanded"
            src="/img/logo-type.svg"
            class="navigation__logo__type"
            contain
            :height="14"
            :max-width="116"
          />
        </router-link>

        <v-divider />
      </div>

      <ChangeBusinessDropdown
        v-if="isBusinessContact"
        :mini="mini"
      />

      <v-divider v-if="isBusinessContact"/>

      <!-- Menu items. The order structure is Chapter > Item > Submenu -->
      <template
        v-for="(chapter, index) in items"
      >
        <v-divider
          v-if="index !== 0 && chapter.submenu.length"
          :key="chapter.title"
        />

        <!--chapters-->
        <v-list
          v-if="chapter.submenu.length"
          :key="chapter.title"
          rounded
          dense
        >
          <v-subheader v-if="!mini">{{ chapter.label }}</v-subheader>

          <template v-for="item in chapter.submenu">
            <!--item with submenu-->
            <v-list-group
              v-if="item.submenu"
              :key="item.title"
              :value="checkActiveSubmenu(item)"
              color="primary"
              ref="itemsWithSubmenu"
              :style="{minHeight: mini ? '36px' : '40px'}"
            >
              <template #activator>
                <v-icon
                  :class="{
                    'mr-4': !mini,
                    'material-icons-outlined': true,
                    'primary--text': checkActiveSubmenu(item)
                  }"
                >
                  {{ item.icon }}
                </v-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                </v-list-item-content>
              </template>

              <!--submenu items-->
              <v-list-item
                v-for="subitem in item.submenu"
                :key="subitem.name"
                :title="subitem.title"
                :to="subitem.paths[0]"
                color="primary"
                ripple
                :class="{'v-list-item--active': checkActiveItem(subitem)}"
                :input-value="checkActiveItem(subitem)"
                prepend-icon="chrome_minimize"
                :value="checkActiveItem(subitem)"
              >
                <div class="navigation__submenu_item_icon">
                  <span />
                </div>

                <v-list-item-content>
                  <v-list-item-title>{{ subitem.label }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-group>

            <!--item-->
            <v-list-item
              v-else
              :key="item.title"
              color="primary"
              :to="item.paths[0]"
              :class="{'v-list-item--active': checkActiveItem(item)}"
              :input-value="checkActiveItem(item)"
              :exact="item.isExact ? item.isExact : false"
              :style="{minHeight: mini ? '36px' : '40px'}"
              @click="shrinkSubmenus"
            >
              <v-icon
                :class="{
                  'mr-4': !mini,
                  'material-icons-outlined': true
                }"
              >
                {{ item.icon }}
              </v-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list>
      </template>

      <!-- Learning Help Center -->
      <template v-if="canSeeFAQ">
        <v-divider/>

        <v-list rounded dense>
          <v-subheader v-if="!mini">Learning</v-subheader>

          <v-list-item
            color="primary"
            href="https://help.business.pepper.com/help"
            target="_blank"
            :style="{minHeight: mini ? '36px' : '40px'}"
          >
            <v-icon
              :class="{
                'mr-4': !mini,
                'material-icons-outlined': true
              }"
            >
              help_outline
            </v-icon>

            <v-list-item-content>
              <v-list-item-title>Help Center</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>

      <v-list
        class="mt-16 navigation__expand-btn"
        :rounded="!mini"
        dense
      >
        <v-list-item
          @click="toggleAlwaysExpanded"
        >
          <v-icon
            :class="{
              'mr-4': !mini,
              'material-icons-outlined': true
            }"
          >
            {{ alwaysExpanded ? 'keyboard_backspace' : 'start' }}
          </v-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ alwaysExpanded ? 'Collapse' : 'Expand' }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <div v-if="!mini" class="navigation__bottom-fixed">
        <v-divider />

        <UserMenu
          :mini="mini"
          :open-logout-dialog="openLogoutDialog"
        />
      </div>
    </v-navigation-drawer>

    <dialog-confirm
      :text="$t('are_you_sure_you_want_to_log_out')"
      :is-on="isLogoutDialogOn"
      :is-loading="isLoggingOut"
      :on-cancel="() => isLogoutDialogOn = false"
      :on-confirm="logout"
      class="logout-confirm"
    />
  </div>
</template>

<script>
  // TODO return columns
  // import columns from '../config/loggedTabs';
  import { isBool } from '../utils';
  import ChangeBusinessDropdown from './dropdowns/ChangeBusiness';
  import UserMenu from './UserMenu';

  const { location } = window;

  export default {
    components: {
      ChangeBusinessDropdown,
      UserMenu,
    },
    data() {
      return {
        isLogoutDialogOn: false,
        isLoggingOut: false,
        alwaysExpanded: false,
        items: [],
        // columns,
        isTermsAndConditionsModalOn: false,
        drawer: true,
        mini: true,
      };
    },
    computed: {
      activeUser() {
        return this.$store.getters['auth/user'];
      },
      isBusinessContact() {
        const { is_business_role } = this.activeUser.role;
        return is_business_role;
      },
      permissions() {
        return this.$store.getters['auth/permissions'];
      },
      canSeeFAQ() {
        const permissions = [{
          subject: 'faq',
          actions: ['see-link-and-download']
        }]
        return this.isAuthorized({permissions});
      }
    },
    watch: {
      activeUser: {
        handler() {
          this.items = this.updateItems();
        },
        immediate: true,
        deep: true
      },
      mini(val) {
        if (!val) {
          // focus main navigation link on hover, to close global country dropdown
          document.getElementById('navigation-logo').focus();
        }
      }
    },
    beforeMount() {
      if (window && window.groove && !window.groove.widget) {
        window.groove.widget = window.groove.createWidget();
        window.groove.widget.init('dc5b458e-d2ca-4fe1-b86d-0ffb0adfa2c3', {});
      }
    },
    created() {
      const { activeUser } = this;
      this.items = this.updateItems();

      if (isBool(activeUser.terms_accepted) && activeUser.terms_accepted === false) {
        setTimeout(() => { this.isTermsAndConditionsModalOn = true; }, 1000);
      }

      const isAlwaysExpanded = localStorage.getItem('isNavigationExpanded')
      if (isAlwaysExpanded !== null) {
        this.alwaysExpanded = !!JSON.parse(isAlwaysExpanded);
        this.mini = !this.alwaysExpanded;
      }

      this.$emit('input', this.alwaysExpanded);
    },
    methods: {
      shrinkSubmenus() {
        if (this.$refs?.itemsWithSubmenu) {
          this.$refs.itemsWithSubmenu.forEach((item) => {
            item.isActive = false;
          });
        }
      },
      openLogoutDialog() {
        this.isLogoutDialogOn = true;
      },
      logout() {
        this.isLoggingOut = true;

        return this.$store
          .dispatch('auth/logout')
          .catch((err) => err)
          .finally(() => {
            this.isLoggingOut = false;
          });
      },
      checkActiveSubmenu(item) {
        return item.submenu.some((subitem) => this.checkActiveItem(subitem))
      },
      checkActiveItem(subitem) {
        const currentPath = this.$route.path;
        let isActive = false;
        subitem?.paths?.forEach(i => {
          if (subitem.isExact && currentPath === i) {
            isActive = true;
          }
          if (!subitem.isExact && currentPath.startsWith(i)) {
            isActive = true;
          }
        });
        return isActive;
      },
      toggleAlwaysExpanded() {
        this.alwaysExpanded = !this.alwaysExpanded;
        setTimeout(() => {
          this.mini = false;
        }, 0)

        localStorage.setItem('isNavigationExpanded', this.alwaysExpanded)
        this.$emit('input', this.alwaysExpanded);
      },
      updateItems() {
        // hardcoded config file (config/LoggedTabs), cos columns for unknown reason is being cached
        // and is not updating after relogin to different role
        const columns = [
          {
            name: 'thread',
            label: 'Deals',
            permissions: [{
              subject: 'thread',
              actions: ['view-nav-link']
            }],
            submenu: [
              {
                name: 'all-deals',
                icon: 'local_offer',
                label: 'All deals',
                paths: ['/threads'],
                permissions: [{
                  subject: 'thread',
                  actions: ['view']
                }],
              },
              {
                name: 'scheduled-threads',
                icon: 'dvr',
                label: 'Scheduled threads',
                paths: ['/scheduled-threads'],
                permissions: [{
                  subject: 'schedule-thread',
                  actions: ['list-all']
                }],
              },
              {
                name: 'pins',
                icon: 'push_pin',
                label: 'Pins',
                paths: ['/pins'],
                permissions: [{
                  subject: 'pin',
                  actions: ['list-all']
                }],
              },
            ]
          },
          {
            name: 'promotions',
            label: 'Promotions',
            permissions: [{
              subject: 'ad-manager.order',
              actions: ['view-nav-link']
            }],
            submenu: [
              {
                name: 'all-promotions',
                icon: 'trending_up',
                label: 'All promotions',
                paths: ['/promotions', '/promotions-archived'],
                permissions: [{
                  subject: 'ad-manager.order',
                  actions: ['view-nav-link']
                }],
                isExact: true,
              },
              {
                name: 'promotions-deals',
                icon: 'local_offer',
                label: 'Promotable deals',
                paths: ['/promotable-deals'],
                permissions: [{
                  subject: 'ad-manager.order',
                  actions: ['view-business-contact']
                }],
                isExact: true
              }
            ]},
          {
            name: 'reports',
            label: 'Analytics',
            permissions: [{
              subject: 'reports-tab',
              actions: ['view']
            }],
            submenu: [
              {
                name: 'performance-reports',
                icon: 'analytics',
                label: 'Reports',
                paths: ['/reports'],
                permissions: [{
                  subject: 'performance-reports',
                  actions: ['list-all']
                }],
                isExact: true
              },
              {
                name: 'daily-reports',
                icon: 'mouse',
                label: 'CPC Reports',
                paths: ['/reports/daily', '/reports/daily/advanced'],
                permissions: [{
                  subject: 'cpc-reports',
                  actions: ['view']
                }],
                isExact: true
              },
              {
                name: 'daily-reports-cpm',
                icon: 'remove_red_eye',
                label: 'CPM Reports',
                paths: ['/reports/daily-cpm'],
                permissions: [{
                  subject: 'cpm-reports',
                  actions: ['view']
                }],
                isExact: true
              }
            ]
          },
          {
            name: 'business',
            label: 'Business',
            permissions: [],
            submenu: [
              {
                name: 'accounts',
                icon: 'domain',
                label: 'Accounts',
                paths: ['/users'],
                permissions: [
                  {
                    subject: 'user',
                    actions: ['view-nav-link']
                  }
                ],
                submenu: [
                  {
                    name: 'business',
                    icon: 'business_center',
                    label: 'Businesses',
                    paths: ['/businesses'],
                    permissions: [{
                      subject: 'business',
                      actions: ['view-nav-link', 'list-all']
                    }],
                  },
                  {
                    name: 'user',
                    icon: 'supervisor_account',
                    label: 'Users',
                    paths: ['/users/pending', '/users'],
                    permissions: [
                      {
                        subject: 'user',
                        actions: ['view-nav-link']
                      }
                    ],
                  },
                ],
              },
              {
                name: 'billing',
                icon: 'receipt_long',
                label: 'Billing',
                permissions: [],
                submenu: [
                  {
                    name: 'payment',
                    icon: 'payment',
                    label: 'Payments',
                    paths: ['/payments'],
                    permissions: [{
                      subject: 'payment',
                      actions: ['list-all']
                    }],
                  },
                  {
                    name: 'invoice',
                    icon: 'event',
                    label: 'Invoices',
                    paths: ['/invoices'],
                    permissions: [{
                      subject: 'invoice',
                      actions: ['list-all']
                    }]
                  },
                  {
                    name: 'billing-accounts',
                    icon: 'credit_card',
                    label: 'Accounts',
                    paths: ['/billing', '/network-review'],
                    permissions: [{
                      subject: 'billing',
                      actions: ['list-all']
                    }],
                  },
                ],
              },
              {
                name: 'billing-reports',
                icon: 'credit_card',
                label: 'Billings',
                paths: ['/reports/monthly'],
                permissions: [{
                  subject: 'billing-reports',
                  actions: ['view']
                }],
              },
              {
                name: 'business-users',
                icon: 'supervisor_account',
                label: 'Members',
                paths: ['/business-users'],
                permissions: [{
                  subject: 'user',
                  actions: ['view-nav-link-business']
                }],
              },
              {
                name: 'contract',
                icon: 'request_quote',
                label: 'Price contracts',
                paths: ['/contracts'],
                permissions: [{
                  subject: 'contract',
                  actions: ['list-all']
                }],
              },
            ]
          },
          {
            name: 'creator_program',
            label: 'Creator program',
            permissions: [
              {
                subject: 'loyalty_program',
                actions: ['list-all'],
              },
            ],
            submenu: [
              {
                name: 'creator-program-businesses',
                icon: 'leaderboard',
                label: 'Creators businesses',
                paths: ['/creator-program/businesses'],
                permissions: [],
              },
              {
                name: 'creator-program-billing',
                icon: 'account_balance_wallet',
                label: 'Billing accounts',
                paths: ['/creator-program/billing-accounts'],
                permissions: [],
              },
            ]
          },
          {
            name: 'administration',
            label: 'Administration',
            permissions: [],
            submenu: [
              {
                name: 'canned-replies',
                icon: 'chat',
                label: 'Canned replies',
                paths: ['/canned-replies'],
                permissions: [{
                  subject: 'comment',
                  actions: ['list-all']
                }],
              },
              {
                name: 'label',
                icon: 'label',
                label: 'Labels',
                paths: ['/labels'],
                permissions: [{
                  subject: 'label',
                  actions: ['view']
                }],
              },
              {
                name: 'internal_users',
                icon: 'badge',
                label: 'Internal users',
                paths: ['/internal-users'],
                permissions: [{
                  subject: 'banner',
                  actions: ['view']
                }],
              },
              {
                name: 'site-messages',
                icon: 'picture_in_picture',
                label: 'Site messages',
                paths: ['/site-messages'],
                permissions: [{
                  subject: 'banner',
                  actions: ['view']
                }],
              },
              {
                name: 'package',
                icon: 'collections_bookmark',
                label: 'Packages',
                paths: ['/packages'],
                permissions: [{
                  subject: 'package',
                  actions: ['view']
                }],
              },
            ]
          },
          // {
          //   name: 'personal_account',
          //   label: 'Personal Account',
          //   permissions: [],
          //   submenu: [
          //     {
          //       name: 'tasks',
          //       icon: 'fact_check',
          //       label: 'Tasks',
          //       paths: ['/tasks'],
          //       permissions: [{
          //         subject: 'task',
          //         actions: ['list-all']
          //       }],
          //     },
          //   ]
          // },
        ];

        // structure is: Chapters -> Items -> Subitems
        // filter chapters
        const filteredItems = columns.filter(chapter => this.isAuthorized(chapter))

        // filter items
        filteredItems.forEach(chapter => {
          if (chapter.submenu.length) {
            // eslint-disable-next-line no-param-reassign
            chapter.submenu = chapter.submenu.filter((item) => {
              const isAuthorized = this.isAuthorized(item)
              if (isAuthorized && item?.submenu?.length) {
                item.submenu = item.submenu.filter(subItem => this.isAuthorized(subItem))
                return 0 < item?.submenu?.length
              } else {
                return isAuthorized
              }
            });
          }
        })

        return filteredItems.map(item => {
          const nextItem = item;

          if (nextItem.submenu.length) {
            nextItem.submenu = nextItem.submenu.map(submenuItem => {
              if (submenuItem.submenu?.length) {
                return {
                  ...submenuItem,
                  isSelected: this.checkActiveSubmenu(submenuItem)
                };
              }

              return {
                ...submenuItem,
              }
            });
          }

          return {
            ...nextItem,
          };
        });
      },
      isAuthorized(item) {
        return this.$store.getters['auth/isAuthorized'](item.permissions);
      },
      onAcceptTerms() {
        this.$store.dispatch('auth/acceptTerms')
          .then(res => {
            this.isTermsAndConditionsModalOn = false;
            return res;
          })
          .catch(error => error);
      }
    }
  };
</script>

<style lang="scss">
  @import '../assets/styles/theme';

  .navigation {
    border-right: 1px solid #e0e0e0;
    max-height: 100vh;

    .theme--light.v-subheader {
      color: map-deep-get($colors, grey, base);
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
      text-transform: uppercase;
    }

    .v-list .v-icon {
      color: rgba(0, 0, 0, 0.87);
    }

    .v-list .v-list-item__title {
      font-weight: 700;
    }

    .v-list .v-list-item--active .v-icon {
      color: inherit;
    }
  }

  .navigation__fake-width {
    width: 52px;
  }

  .navigation__expand-btn {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
  }

  .navigation__logo {
    align-items: center;
    background-color: #fff;
    display: flex;
    height: 72px;
    padding: 0 24px 0;
    transition: all .2s;
    gap: 4px;

    &__type {
      margin: 6px 0 2px;
    }

    &:focus,
    &:active,
    &:focus-visible {
      outline: none;
    }
  }

  .navigation__top-fixed {
    background-color: #fff;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    z-index: 1;
  }

  .navigation__bottom-fixed {
    background-color: #fff;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    bottom: 0;
    z-index: 1;
  }

  .navigation__submenu_item_icon {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 24px;
    margin-right: 16px;
    width: 24px;

    span {
      background-color: map-get($colors, primary);
      height: 100%;
      transition: width .3s ease-in-out;
      width: 1px;
    }
  }

  .v-list-item.v-list-item--active {
    .navigation__submenu_item_icon span {
      border-radius: 3px;
      height: 19px;
      width: 19px;
    }
  }

  .v-navigation-drawer--fixed {
    overflow: visible;
  }

  .v-navigation-drawer--is-mouseover.v-navigation-drawer--open-on-hover {
    border-right: 1px solid #E0E0E0;
  }

  .v-navigation-drawer__content {
    display: flex;
    flex-direction: column;
  }

  // Hide scrollbar for navigation drawer
  .v-navigation-drawer::-webkit-scrollbar,
  .v-navigation-drawer__content::-webkit-scrollbar{
    display: none;
  }
  .v-navigation-drawer,
  .v-navigation-drawer__content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  /* Hide scrollbar for content */
  .v-navigation-drawer--mini-variant .v-navigation-drawer__content::-webkit-scrollbar {
    display: none;
  }
  .v-navigation-drawer--mini-variant .v-navigation-drawer__content {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .v-navigation-drawer--mini-variant {
    .navigation__logo {
      padding: 0 16px 0;
    }
  }
</style>
