import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';
import {isNonEmptyStr} from "@/utils";

export default {
  getOrder({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/orders/${id}`,
    });
  },
  getOrders(options = {}) {
    const {
      archived = 0, // by default, show only active orders
      page = 1,
      perPage = maxItemsPerPage,
      sort = '',
      order = '',
      search = '',
      status = -1,
      date_filter = '',
      businessId = -1,
      advertiserId = -1,
      countries = [],
      created_by = '',
      order_type = -1,
      contract_name = -1,
    } = options;

    const params = {
      page,
      countries,
      archived,
    };

    if (date_filter !== '') {
      params.date_filter = date_filter;
    }

    if (sort !== '') {
      params.sort = sort;
    }

    if (order_type > -1) {
      params.order_contract_type = order_type.toString() + '.0';
    }

    if (contract_name > -1) {
      params.contract_pipedrive_deal_id = contract_name;
    }

    if (order !== '') {
      params.order = order;
    }

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (isNonEmptyStr(search)) {
      params.search = search;
    }

    if (status > -1) {
      params.status = status;
    }

    if (businessId > -1) {
      params.business_id = businessId;
    }

    if (advertiserId > -1) {
      params.advertiser_id = advertiserId;
    }

    if (created_by !== '') {
      params.created_by = created_by;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/orders`,
      params,
    });
  },
  getLineItemById(options = {}) {
    const { orderId = -1, lineId = -1 } = options;

    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/orders/${orderId}/lines/${lineId}`,
    });
  },
  getAdvertisers(options = {}) {
    const { perPage = -1, search = '' , country } = options;

    const params = {
      perPage,
      search,
      country
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/advertisers`,
      params,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/orders/statuses`,
    });
  },
  getOrderStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/orders/actions`,
    });
  },
  getLineStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/lines/actions`,
    });
  },
  getTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/lines/types`,
    });
  },
  getCostTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/lines/cost_types`,
    });
  },
  getPlacements(params) {
    // possible params:
    //  business: Boolean
    //  country: String
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/targeting/placements`,
      params,
    });
  },
  getPlacementsTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/targeting/placements-filter-types`,
    });
  },
  getDevices() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/targeting/devices`,
    });
  },
  getAccountTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/orders/account_types`,
    });
  },
  getOrderTypes() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/orders/order-contract-types`,
    });
  },
  getOrderTypesForFilter() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/orders/all-order-contract-types`,
    });
  },
  getContractNames(options = {}) {
    const {
      country = '',
      search = '',
    } = options;

    const params = {};

    if (search.length > 2) {
      params.title_name_search = search;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/${country}/pipedrive/deals/search`,
      params
    });
  },
  getForecast(options) {
    const {
      lineId,
      cost_type = '',
      country = '',
      start_date = '',
      end_date = '',
      targeting = {},
      type = '',
      units = '',
      frequency_caps = [],
    } = options;

    const formData = {
      country,
      type,
      targeting,
      start_date,
      end_date,
      units,
      cost_type,
      frequency_caps,
    };

    const lineParam = {
      line_id: lineId,
    };

    const data = lineId ? null : formData;
    const params = lineId ? lineParam : null;

    return axios({
      method: 'post',
      url: `${apiUrl}/google_manager/inventory`,
      data,
      params,
    });
  },
  getTargetGroups(country) {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/${country}/targeting/groups`,
    });
  },
  getTargetUsers() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/targeting/users`,
    });
  },
  getFreqTimeUnits() {
    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/lines/frequency_time_units`,
    });
  },
  getGeo(options) {
    const { perPage = 10, search = '', country = '' } = options;

    const params = {
      perPage,
      search,
      country,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/${country}/targeting/geo`,
      params,
    });
  },
  getThreads(options) {
    const {
      country,
      merchant_ids,
      group_id,
      thread_id,
      placement_id,
      page,
    } = options;

    const params = {
      placement_id,
      country
    };

    if (merchant_ids.length) {
      params.merchant_ids = merchant_ids;
    }

    if (group_id) {
      params.group_id = group_id;
    }

    if (thread_id) {
      params.thread_id = thread_id;
    }

    if (page) {
      params.page = page;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/threads`,
      params,
    });
  },
  getBusinessMerchants(query) {
    const { country } = query;

    const params = {country};

    return axios({
      method: 'get',
      url: `${apiUrl}/google_manager/merchants`,
      params
    });
  },
  addBusinessMerchants({ country, merchants }) {
    return axios({
      method: 'post',
      url: `${apiUrl}/google_manager/merchants`,
      data: {
        merchants,
        country
      },
    });
  },
  // todo: check if country is required
  getGroups(options) {
    const { search = '', country = '' } = options;

    const params = {
      search,
    };

    return axios({
      method: 'get',
      url: `${apiUrl}/pepper/${country}/groups`,
      params,
    });
  },
  createOrder(order) {
    return axios({
      method: 'post',
      url: `${apiUrl}/google_manager/orders`,
      data: {
        ...order,
      },
    });
  },
  updateOrder(order) {
    const { id } = order;
    return axios({
      method: 'put',
      url: `${apiUrl}/google_manager/orders/${id}`,
      data: {
        ...order,
      },
    });
  },
  createLineItem(lineItem) {
    return axios({
      method: 'post',
      url: `${apiUrl}/google_manager/orders/${lineItem.order_id}/lines`,
      data: {
        ...lineItem,
      },
    });
  },
  updateLineItem(lineItem) {
    return axios({
      method: 'put',
      url: `${apiUrl}/google_manager/orders/${lineItem.order_id}/lines/${lineItem.id}`,
      data: {
        ...lineItem,
      },
    });
  },
  updateStatus(order) {
    const { id, statusId } = order;
    return axios({
      method: 'post',
      url: `${apiUrl}/google_manager/orders/${id}/action/${statusId}`,
    });
  },
  updateLineStatus(query) {
    const { orderId, lineItemId, statusId } = query;
    return axios({
      method: 'post',
      url: `${apiUrl}/google_manager/orders/${orderId}/lines/${lineItemId}/action/${statusId}`,
    });
  },
};
