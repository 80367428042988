import VuexPersistence from 'vuex-persist';
import initialState from './initialState';

const { localStorage } = window;

export default new VuexPersistence({
  storage: localStorage,
  key: 'pepper_business',
  reducer: (state) => ({
    ...state,
    alerts: initialState.alerts,
    popups: initialState.popups,
    threads: {
      ...initialState.threads,
      commentPermissions: state.threads.commentPermissions,
    },
    shops: initialState.shops,
    reports: initialState.reports,
    comments: initialState.comments,
    contracts: {
      ...initialState.contracts,
      statuses: state.contracts.statuses,
      types: state.contracts.types,
    },
    uris: initialState.uris,
    billing: initialState.billing,
    payments: {
      ...initialState.statuses,
      statuses: state.payments.statuses,
    },
    invoices: {
      ...initialState.statuses,
      statuses: state.payments.statuses,
    },
    labels: initialState.labels,
    businesses: {
      ...initialState.businesses,
      statuses: state.businesses.statuses,
      notificationTypes: state.businesses.notificationTypes,
    },
    users: {
      ...initialState.users,
      roles: state.users.roles,
      statuses: state.users.statuses,
      countries: state.users.countries,
    },
    validation: initialState.validation,
    pins: initialState.pins,
    packages: initialState.packages,
    bumps: initialState.bumps,
    schedules: initialState.schedules,
    siteMessages: {
      ...initialState.siteMessages,
      statuses: state.siteMessages.statuses,
    },
    grids: initialState.grids,
    tasks: {
      ...initialState.tasks,
      filters: state.tasks.filters,
      statuses: state.tasks.statuses,
      types: state.tasks.types,
      actions: state.tasks.actions,
    },
    adManager: initialState.adManager,
  }),
});
