import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { maxItemsPerPage, apiUrl } from './index';
import { isStr } from '../../utils';

export default {
  getItems(options = {}) {
    const {
      countries = [],
      page = 1,
      perPage = maxItemsPerPage,
      order = 'asc',
      sort = '',
      search = '',
      status = -1,
      business = -1,
      contract_id = -1,
      advertiser_id = -1,
      created_at = '',
    } = options;

    const params = {
      countries,
      page,
      order,
      sort,
      search,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    if (status > -1) {
      params.status = status;
    }

    if (business > -1) {
      params.business_id = business;
    }

    if (contract_id > -1) {
      params.contract_id = contract_id;
    }

    if (advertiser_id > -1) {
      params.advertiser_id = advertiser_id;
    }

    if (created_at !== '') {
      params.created_at = created_at;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/invoices`,
      params,
    });
  },
  getAccounts() {
    return axios({
      method: 'get',
      url: `${apiUrl}/running_invoices/accounts`,
    });
  },
  getAccount({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/running_invoices/accounts/${id}`
    });
  },
  updateItem(invoice) {
    return axios({
      method: 'patch',
      url: `${apiUrl}/invoices/${invoice.id}`,
      data: {
        ...invoice,
      },
    });
  },
  getItem({ id }) {
    return axios({
      method: 'get',
      url: `${apiUrl}/invoices/${id}`,
    });
  },
  getStatuses() {
    return axios({
      method: 'get',
      url: `${apiUrl}/invoices/statuses`,
    });
  },
  getActivityLogs({ page = 1, perPage = maxItemsPerPage, id }) {
    const params = {
      page,
    };

    if (perPage > -1) {
      params.per_page = perPage;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/invoices/${id}/activities`,
      params,
    });
  },
  generatePdf({ id }) {
    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/invoices/${id}/download`,
    });
  },
  downloadInvoice({ id }) {
    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/invoices/${id}/download`,
    });
  },
  downloadPublicInvoice({ token, source, emailId }) {
    const emailPath = isStr(emailId) ? `/${emailId}` : '';

    return axios({
      method: 'get',
      responseType: 'blob',
      url: `${apiUrl}/invoices/download/${token}/${source}${emailPath}`,
    });
  },
};
