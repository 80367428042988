import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '@/utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const creatorProgramApi = apiClient.merchy.creatorProgram

/**
 * @description Is valid
 * @param items
 * @returns {boolean}
 */
const isValid = (items) => {
  const {
    activeBillingAccount,
    activeBusiness,
    activeInvoice,
    businesses,
    businessesMeta,
    billingAccounts,
    billingAccountsMeta,
    invoices,
    invoicesMeta
  } = items;

  return isObj(activeBillingAccount)
    && isObj(activeBusiness)
    && isObj(activeInvoice)
    && isArr(businesses)
    && isObj(businessesMeta)
    && isArr(billingAccounts)
    && isObj(billingAccountsMeta)
    && isArr(invoices)
    && isObj(invoicesMeta)
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = initialState => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial creator program state');
  }

  const {
    activeBillingAccount,
    activeBusiness,
    activeInvoice,
    businesses,
    businessesMeta,
    billingAccounts,
    billingAccountsMeta,
    invoices,
    invoicesMeta
  } = initialState;

  return {
    activeBillingAccount,
    activeBusiness,
    activeInvoice,
    businesses,
    businessesMeta,
    billingAccounts,
    billingAccountsMeta,
    invoices,
    invoicesMeta
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  activeBillingAccount: ({ activeBillingAccount }) => activeBillingAccount,
  activeBusiness: ({ activeBusiness }) => activeBusiness,
  activeInvoice: ({ activeInvoice }) => activeInvoice,
  businesses: ({ businesses }) => businesses,
  businessesMeta: ({ businessesMeta }) => businessesMeta,
  billingAccounts: ({ billingAccounts }) => billingAccounts,
  billingAccountsMeta: ({ billingAccountsMeta }) => billingAccountsMeta,
  invoices: ({ invoices }) => invoices,
  invoicesMeta: ({ invoicesMeta }) => invoicesMeta
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') => (
  store.dispatch('alerts/set', {
    data,
    alertType
  })
);

const actions = {
  createBusiness: (_, query) => (
    creatorProgramApi.createBusiness(query).then((res) => {
      handleAlerts({
        data: {
          message: 'Business created successfully.'
        }
      }, 'success')

      return res;
    }).catch(e => Promise.reject(e))
  ),
  createBillingAccount: ({ commit, state }, query) => (
    creatorProgramApi.createBillingAccount(query).then((res) => {
      handleAlerts({
        data: {
          message: 'Billing account created successfully.'
        }
      }, 'success')

      return res;
    }).catch(e => Promise.reject(e))
  ),
  updateBusiness: (_, query) => (
    creatorProgramApi.updateBusiness(query).then((res) => {
      handleAlerts({
        data: {
          message: 'Business updated successfully.'
        }
      }, 'success')

      return res;
    }).catch(e => Promise.reject(e))
  ),
  updateBillingAccount: (_, query) => (
    creatorProgramApi.updateBillingAccount(query).then((res) => {
      handleAlerts({
        data: {
          message: 'Billing account updated successfully.'
        }
      }, 'success')

      return res;
    }).catch(e => Promise.reject(e))
  ),
  getBusiness: ({ commit, state }, query) => (
    creatorProgramApi.getBusiness(query).then((res) => {
      const nextActiveBusiness = {
        ...res.data.data
      };

      const nextState = {
        ...state,
        activeBusiness: nextActiveBusiness
      };

      commit('SET', nextState);

      return nextActiveBusiness;
    }).catch(e => Promise.reject(e))
  ),
  getBusinesses: ({ commit, state }, query) => (
    creatorProgramApi.getBusinesses(query).then((res) => {
      const {data, meta} = res.data;

      const nextItems = {
        businesses: data,
        businessesMeta: meta
      };

      const nextState = {
        ...state,
        ...nextItems
      };

      commit('SET', nextState);

      return nextItems;
    }).catch(e => Promise.reject(e))
  ),
  getBillingAccount: ({ commit, state }, query) => (
    creatorProgramApi.getBillingAccount(query).then((res) => {
      const nextActiveBillingAccount = {
        ...res.data.data
      };

      const nextState = {
        ...state,
        activeBillingAccount: nextActiveBillingAccount
      };

      commit('SET', nextState);

      return nextActiveBillingAccount;
    }).catch(e => Promise.reject(e))
  ),
  getBillingAccounts: ({ commit, state }, query) => (
    creatorProgramApi.getBillingAccounts(query).then((res) => {
      const {data, meta} = res.data;

      const nextItems = {
        billingAccounts: data,
        billingAccountsMeta: meta
      };

      const nextState = {
        ...state,
        ...nextItems
      };

      commit('SET', nextState);

      return nextItems;
    }).catch(e => Promise.reject(e))
  ),
  setActiveBusiness: ({ commit, state }, business) => {
    commit('SET', {
      ...state,
      activeBusiness: business
    });
  },
  reset: ({ commit }) => (
    commit('SET', initialState.creatorProgram)
  ),
  set: ({ commit }, creatorProgram) => {
    commit('SET', creatorProgram);
  }
};

const mutations = {
  SET(state, creatorProgram) {
    /* eslint-disable no-param-reassign */
    state.activeBillingAccount = creatorProgram.activeBillingAccount;
    state.activeBusiness = creatorProgram.activeBusiness;
    state.activeInvoice = creatorProgram.activeInvoice;
    state.businesses = creatorProgram.businesses;
    state.businessesMeta = creatorProgram.businessesMeta;
    state.billingAccounts = creatorProgram.billingAccounts;
    state.billingAccountsMeta = creatorProgram.billingAccountsMeta;
    state.invoices = creatorProgram.invoices;
    state.invoicesMeta = creatorProgram.invoicesMeta;
  }
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
