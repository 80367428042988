<template>
  <component :is="iconComponentName"></component>
</template>

<script>
  import DealabsIcon from '../assets/img/platforms/Dealabs'
  import HotukdealsIcon from '../assets/img/platforms/HotukDeals'
  import MydealzIcon from '../assets/img/platforms/MyDealz'
  import PepperIcon from '../assets/img/platforms/Pepper'
  import PricejeagerIcon from '../assets/img/platforms/PriceJeager'
  import PromodescuentosIcon from '../assets/img/platforms/PromoDescuentos'
  export default {
    props: {
      country: {
        type: String,
        default: ''
      }
    },
    components: {
      DealabsIcon,
      HotukdealsIcon,
      MydealzIcon,
      PepperIcon,
      PricejeagerIcon,
      PromodescuentosIcon
    },
    computed: {
      iconComponentName() {
        switch (this.country) {
          case 'DE':
            return 'MydealzIcon';
          case 'GB':
            return 'HotukdealsIcon';
          case 'FR':
            return 'DealabsIcon';
          case 'AT':
            return 'PricejeagerIcon';
          case 'MX':
            return 'PromodescuentosIcon';
          default:
            return 'PepperIcon';
        }
      },
    }
  }
</script>

<style scoped lang="scss">

</style>
