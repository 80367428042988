const meta = {
  loadState: true,
  auth: true,
  isAuthPage: false,
  permissions: [
    {
      subject: 'loyalty_program',
      actions: ['list-all'],
    }
  ],
}

export default [
  {
    path: '/creator-program',
    name: 'creator-program',
    component: () => import('../../views/Proxy.vue'),
    meta,
    children: [
      {
        path: 'businesses',
        name: 'creator-program-businesses',
        meta,
        component: () => import('../../views/creatorProgram/Businesses.vue'),
        children: [
          {
            path: 'new',
            name: 'creator-program-businesses-new',
            meta,
            component: () => import('../../components/dialogs/creatorProgram/Business.vue'),
          },
          {
            path: ':id(\\d+)',
            name: 'creator-program-businesses-details',
            meta,
            // route level code-splitting
            component: () => import('../../components/dialogs/creatorProgram/Business.vue'),
          },
        ]
      },
      {
        path: 'billing-accounts',
        name: 'creator-program-billing-accounts',
        meta,
        component: () => import('../../views/creatorProgram/Billing.vue'),
        children: [
          {
            path: 'new',
            name: 'creator-program-billing-accounts-new',
            meta,
            component: () => import('../../components/dialogs/creatorProgram/BillingAccount.vue'),
          },
          {
            path: ':id(\\d+)',
            name: 'creator-program-billing-accounts-details',
            meta,
            // route level code-splitting
            component: () => import('../../components/dialogs/creatorProgram/BillingAccount.vue'),
          },
        ]
      },
    ]
  },
];
