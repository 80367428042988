import _ from 'lodash';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
// eslint-disable-next-line import/no-cycle
import router from '../../router';
import { isObj, isArr, sortCountries } from '@/utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const businessesApi = apiClient.merchy.businesses;

/**
 * @description Is valid
 * @param businesses
 * @returns {boolean}
 */
const isValid = (businesses) => {
    const { activeItem, items, itemsMeta, notificationTypes, enabledCountries, statuses } = businesses;

    const isValidActiveItem = isObj(activeItem)
        && isObj(activeItem.settings)
        && isArr(activeItem.billingAccounts)
        && isArr(activeItem.labels)
        && isArr(activeItem.comments)
        && isArr(activeItem.campaigns)
        && isArr(activeItem.threadSubmissions)
        && isArr(activeItem.enabledPromotions)
        && isArr(activeItem.threadSubmissionLimits)
        && isArr(activeItem.notifications)
        && isArr(activeItem.keyAccounts)
        && isArr(activeItem.accountManagers)
        && isArr(activeItem.businessAdvertisers)
        && isArr(activeItem.billingAccountAdvertisers)
        && isArr(activeItem.billingCountriesByStatus)
        && isArr(activeItem.businessMerchants)
        && isObj(activeItem.log)
        && isArr(activeItem.notes)
        && isArr(activeItem.log.items)
        && isObj(activeItem.log.meta);

    return isObj(businesses)
        && isArr(items)
        && isObj(itemsMeta)
        && isArr(notificationTypes)
        && isValidActiveItem
        && isArr(enabledCountries)
        && isArr(statuses);
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = initialState => {
    if (!isValid(initialState)) {
        throw Error('Invalid initial businesses state');
    }

    const { activeItem, items, itemsMeta, notificationTypes, statuses, enabledCountries } = initialState;
    return {
        activeItem,
        items,
        itemsMeta,
        notificationTypes,
        enabledCountries,
        statuses
    };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
    activeItem: ({ activeItem }) => activeItem,
    items: ({ items }) => items,
    itemsMeta: ({ itemsMeta }) => itemsMeta,
    itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
    statuses: ({ statuses }) => statuses,
    notificationTypes: ({ notificationTypes }) => notificationTypes || [],
    enabledCountries: ({ enabledCountries }) => enabledCountries
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') => (
    store.dispatch('alerts/set', {
        data,
        alertType
    })
);

const actions = {
    create: (context, query) => (
        businessesApi.createItem(query).then(() => (
            handleAlerts({
                data: {
                    message: 'Business created successfully.'
                }
            }, 'success')
        ))
    ),
    update: ({ commit, state }, query) => (
        businessesApi.updateItem(query).then((res) => {
            const { activeItem } = state;
            const nextItem = res.data.data;

            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    ...nextItem
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Business updated successfully.'
                }
            }, 'success');
        })
    ),
    getItem: ({ commit, state }, query) => (
        businessesApi.getItem(query).then((res) => {
            const { activeItem } = state;

            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    ...res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    getItems: ({ commit, state }, query) => (
        businessesApi.getItems(query).then((res) => {
            const { data, meta } = res.data;
            const nextItems = {
                items: data,
                itemsMeta: meta
            };

            const nextState = {
                ...state,
                ...nextItems
            };

            commit('SET', nextState);

            return nextItems;
        })
    ),
    getStatuses: ({ commit, state }) => (
        businessesApi.getStatuses().then((res) => {
            const nextStatuses = _.sortBy(res.data.data, status => status.name.toLowerCase());
            const nextState = {
                ...state,
                statuses: nextStatuses
            };

            return commit('SET', nextState);
        })
    ),
    setActiveItem: ({ commit, state }, nextActiveItem) => {
        const nextState = {
            ...state,
            activeItem: {
                ...state.activeItem,
                ...nextActiveItem
            }
        };

        return commit('SET', nextState);
    },
    getActivityLogs: ({ commit, state }, query) => (
        businessesApi.getActivityLogs(query).then((res) => {
            const { activeItem } = state;
            const { data, meta } = res.data;

            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    log: {
                        items: data,
                        meta
                    }
                }
            };

            return commit('SET', nextState);
        })
    ),
    getBillingSettings: ({ commit, state }) => (
        businessesApi.getBillingSettings().then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    billingSettings: res.data.data,
                }
            };

            return commit('SET', nextState);
        })
    ),
    getBillingCountriesByStatus: ({ commit, state }) => (
        businessesApi.getBillingCountriesByStatus().then(res => {
            const { activeItem } = state;

            const countriesByStatus = res.data.data;
            const combined = [];

            for (const key in countriesByStatus) {
              combined.push(...countriesByStatus[key]);
            }

            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    billingCountriesByStatus: countriesByStatus,
                    disabledForPromotionCountries: sortCountries(combined) // for simpler access + sorted
                }
            };

            return commit('SET', nextState);
        })
    ),
    getSettings: ({ commit, state }, query) => (
        businessesApi.getSettings(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    settings: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    updateSettings: ({ commit, state }, query) => (
        businessesApi.updateSettings(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    ...res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Business settings updated successfully.'
                }
            }, 'success');
        })
    ),
    getBillingAccounts: ({ commit, state }, query) => (
        businessesApi.getBillingAccounts(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    billingAccounts: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addBillingAccount: ({ commit, state }, query) => (
        businessesApi.addBillingAccount(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    billingAccounts: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Billing account added successfully.'
                }
            }, 'success');
        })
    ),
    removeBillingAccount: ({ commit, state }, query) => (
        businessesApi.removeBillingAccount(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    billingAccounts: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Billing account removed successfully.'
                }
            }, 'success');
        })
    ),
    getLabels: ({ commit, state }, query) => (
        businessesApi.getLabels(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    labels: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addLabels: ({ commit, state }, query) => (
        businessesApi.addLabels(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    labels: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Labels added successfully.'
                }
            }, 'success');
        })
    ),
    removeLabels: ({ commit, state }, query) => (
        businessesApi.removeLabels(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    labels: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Labels removed successfully.'
                }
            }, 'success');
        })
    ),
    getCommentCountries: ({ commit, state }, query) => (
        businessesApi.getCommentCountries(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    comments: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addCommentCountry: ({ commit, state }, query) => (
        businessesApi.addCommentCountry(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    comments: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Labels added successfully.'
                }
            }, 'success');
        })
    ),
    removeCommentCountry: ({ commit, state }, query) => (
        businessesApi.removeCommentCountry(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    comments: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Labels removed successfully.'
                }
            }, 'success');
        })
    ),
    getCampaigns: ({ commit, state }, query) => (
        businessesApi.getCampaigns(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    campaigns: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addCampaign: ({ commit, state }, query) => (
        businessesApi.addCampaign(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    campaigns: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Campaign enabled successfully.'
                }
            }, 'success');
        })
    ),
    removeCampaign: ({ commit, state }, query) => (
        businessesApi.removeCampaign(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    campaigns: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Campaign disabled successfully.'
                }
            }, 'success');
        })
    ),
    getThreadSubmissions: ({ commit, state }, query) => {
        return businessesApi.getThreadSubmissions(query).then((res) => {
            const { activeItem } = state;

            const countries = res.data.data;

            const nextState = {
                ...state,
                enabledCountries: res.data.data,
                activeItem: {
                    ...activeItem,
                    threadSubmissions: countries
                },
            };

            commit('SET', nextState);

            return res;
        })
    },
    addThreadSubmission: ({ commit, state }, query) => (
        businessesApi.addThreadSubmission(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    threadSubmissions: res.data.data
                }
            };

            commit('SET', nextState);

            handleAlerts({
                data: {
                    message: 'Thread submission enabled successfully.'
                }
            }, 'success');

            return res.data.data;
        })
    ),
    removeThreadSubmission: ({ commit, state }, query) => (
        businessesApi.removeThreadSubmission(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    threadSubmissions: res.data.data
                }
            };

            commit('SET', nextState);

            handleAlerts({
                data: {
                    message: 'Thread submission disabled successfully.'
                }
            }, 'success');

            return res.data.data;
        })
    ),
    getEnabledPromotions: ({ commit, state }, query) => (
        businessesApi.getEnabledPromotions(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    enabledPromotions: res.data.data
                }
            };

            commit('SET', nextState);

            return res;
        })
    ),
    addEnabledPromotion: ({ commit, state }, query) => (
        businessesApi.addEnabledPromotion(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    enabledPromotions: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: router.app.$t('promotion_country_enabled')
                }
            }, 'success');
        })
    ),
    removeEnabledPromotion: ({ commit, state }, query) => (
        businessesApi.removeEnabledPromotion(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    enabledPromotions: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: router.app.$t('promotion_country_disabled')
                }
            }, 'success');
        })
    ),
    getThreadSubmissionsLimits: ({ commit, state }, query) => (
        businessesApi.getThreadSubmissionsLimits(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    threadSubmissionLimits: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addThreadSubmissionLimit: ({ commit, state }, query) => (
        businessesApi.addThreadSubmissionLimit(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    threadSubmissionLimits: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Thread submission limit added successfully.'
                }
            }, 'success');
        })
    ),
    removeThreadSubmissionLimit: ({ commit, state }, query) => (
        businessesApi.removeThreadSubmissionLimit(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    threadSubmissionLimits: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Thread submission limit removed successfully.'
                }
            }, 'success');
        })
    ),
    getNotifications: ({ commit, state }, query) => (
        businessesApi.getNotifications(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    notifications: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addNotification: ({ commit, state }, query) => (
        businessesApi.addNotification(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    notifications: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Notification added successfully.'
                }
            }, 'success');
        })
    ),
    removeNotification: ({ commit, state }, query) => (
        businessesApi.removeNotification(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    notifications: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Notification removed successfully.'
                }
            }, 'success');
        })
    ),
    getKeyAccounts: ({ commit, state }, query) => (
        businessesApi.getKeyAccounts(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    keyAccounts: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addKeyAccount: ({ commit, state }, query) => (
        businessesApi.addKeyAccount(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    keyAccounts: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Key account added successfully.'
                }
            }, 'success');
        })
    ),
    removeKeyAccount: ({ commit, state }, query) => (
        businessesApi.removeKeyAccount(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    keyAccounts: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Key account removed successfully.'
                }
            }, 'success');
        })
    ),
    getAccountManagers: ({ commit, state }, query) => (
        businessesApi.getAccountManagers(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    accountManagers: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addAccountManager: ({ commit, state }, query) => (
        businessesApi.addAccountManager(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    accountManagers: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Account manager added successfully.'
                }
            }, 'success');
        })
    ),
    removeAccountManager: ({ commit, state }, query) => (
        businessesApi.removeAccountManager(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    accountManagers: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Account manager removed successfully.'
                }
            }, 'success');
        })
    ),
    getBusinessAdvertisers: ({ commit, state }, query) => (
        businessesApi.getBusinessAdvertisers(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessAdvertisers: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addBusinessAdvertiser: ({ commit, state }, query) => (
        businessesApi.addBusinessAdvertiser(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessAdvertisers: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Advertiser added successfully.'
                }
            }, 'success');
        })
    ),
    removeBusinessAdvertiser: ({ commit, state }, query) => (
        businessesApi.removeBusinessAdvertiser(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessAdvertisers: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Advertiser removed successfully.'
                }
            }, 'success');
        })
    ),
    getBillingAccountAdvertisers: ({ commit, state }, query) => (
        businessesApi.getBillingAccountAdvertisers(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    billingAccountAdvertisers: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addBillingAccountAdvertiser: ({ commit, state }, query) => (
        businessesApi.addBillingAccountAdvertiser(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    billingAccountAdvertisers: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: router.app.$t('billing_account_added')
                }
            }, 'success');
        })
    ),
    removeBillingAccountAdvertiser: ({ commit, state }, query) => (
        businessesApi.removeBillingAccountAdvertiser(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    billingAccountAdvertisers: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: router.app.$t('billing_account_removed')
                }
            }, 'success');
        })
    ),
    getBusinessMerchants: ({ commit, state }, query) => (
        businessesApi.getBusinessMerchants(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessMerchants: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addBusinessMerchant: ({ commit, state }, query) => (
        businessesApi.addBusinessMerchant(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessMerchants: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Merchant added successfully.'
                }
            }, 'success');
        })
    ),
    removeBusinessMerchant: ({ commit, state }, query) => (
        businessesApi.removeBusinessMerchant(query).then((res) => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessMerchants: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: 'Merchant removed successfully.'
                }
            }, 'success');
        })
    ),
    getBusinessPlacements: ({ commit, state }, query) => (
        businessesApi.getBusinessPlacements(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessPlacements: res.data.data
                }
            };

            commit('SET', nextState);

            return res.data.data;
        })
    ),
    getBusinessCpmPrices: ({ commit, state }, query) => (
        businessesApi.getBusinessCpmPrices(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessCpmPrices: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addBusinessCpmPrice: ({ commit, state }, query) => (
        businessesApi.addBusinessCpmPrice(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessCpmPrices: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: router.app.$t('cpm_price_added')
                }
            }, 'success');
        })
    ),
    removeBusinessCpmPrice: ({ commit, state }, query) => (
        businessesApi.removeBusinessCpmPrice(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessCpmPrices: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: router.app.$t('cpm_price_removed')
                }
            }, 'success');
        })
    ),
    getBusinessFreeImpressions: ({ commit, state }, query) => (
        businessesApi.getBusinessFreeImpressions(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessFreeImpressions: res.data.data
                }
            };

            return commit('SET', nextState);
        })
    ),
    addBusinessFreeImpressions: ({ commit, state }, query) => (
        businessesApi.addBusinessFreeImpressions(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessFreeImpressions: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: router.app.$t('free_impressions_added')
                }
            }, 'success');
        })
    ),
    removeBusinessFreeImpressions: ({ commit, state }, query) => (
        businessesApi.removeBusinessFreeImpressions(query).then(res => {
            const { activeItem } = state;
            const nextState = {
                ...state,
                activeItem: {
                    ...activeItem,
                    businessFreeImpressions: res.data.data
                }
            };

            commit('SET', nextState);

            return handleAlerts({
                data: {
                    message: router.app.$t('free_impressions_removed')
                }
            }, 'success');
        })
    ),
    getNotificationTypes: ({ commit, state }) => (
        businessesApi.getNotificationTypes().then((res) => {
            const nextState = {
                ...state,
                notificationTypes: res.data.data
            };

            return commit('SET', nextState);
        })
    ),
    getNotes: ({ commit, state }, query) => (
        businessesApi.getNotes(query).then((res) => {
            const notes = res.data.data;
            const nextActiveItem = {
                ...state.activeItem,
                notes
            };

            const nextState = {
                activeItem: nextActiveItem
            };

            commit('SET', nextState);

            return nextActiveItem;
        })
    ),
    addNote: ({ commit, state }, query) => (
        businessesApi.addNote(query).then((res) => {
            const notes = res.data.data;
            const nextActiveItem = {
                ...state.activeItem,
                notes
            };

            const nextState = {
                activeItem: nextActiveItem
            };

            commit('SET', nextState);

            return nextActiveItem;
        })
    ),
    updateNote: ({ commit, state }, query) => (
        businessesApi.updateNote(query).then((res) => {
            const notes = res.data.data;
            const nextActiveItem = {
                ...state.activeItem,
                notes
            };

            const nextState = {
                activeItem: nextActiveItem
            };

            commit('SET', nextState);

            return nextActiveItem;
        })
    ),
    removeNote: ({ commit, state }, query) => (
        businessesApi.removeNote(query).then((res) => {
            const notes = res.data.data;
            const nextActiveItem = {
                ...state.activeItem,
                notes
            };

            const nextState = {
                activeItem: nextActiveItem
            };

            commit('SET', nextState);

            return nextActiveItem;
        })
    ),
    getBusinessThreads: (context, query) => (
        businessesApi.getBusinessThreads(query).then((res) => res.data.data)
    ),
    reset: ({ commit }) => (
        commit('SET', initialState.businesses)
    ),
    set: ({ commit }, businesses) => {
        commit('SET', businesses);
    }
};

const mutations = {
    SET(state, businesses) {
        /* eslint-disable no-param-reassign */
        state.activeItem = businesses.activeItem;
        state.items = businesses.items;
        state.itemsMeta = businesses.itemsMeta;
        state.statuses = businesses.statuses;
        state.notificationTypes = businesses.notificationTypes;
        state.enabledCountries = businesses.enabledCountries;
    }
};

export default initialState => ({
    namespaced: true,
    state: initState(initialState),
    getters,
    actions,
    mutations,
});
