import _ from 'lodash';
import download from 'downloadjs';
// eslint-disable-next-line import/no-cycle
import apiClient from '../../api';
import { isObj, isArr } from '../../utils';
// eslint-disable-next-line import/no-cycle
import store from '..';
import initialState from '../initialState';

const invoicesApi = apiClient.merchy.invoices;

/**
 * @description Is valid
 * @param invoices
 * @returns {boolean}
 */
const isValid = (invoices) => {
  const { activeItem, items, itemsMeta, statuses } = invoices;

  const isValidActiveItem =
    isObj(activeItem) &&
    isObj(activeItem.log) &&
    isArr(activeItem.log.items) &&
    isObj(activeItem.log.meta);

  return (
    isObj(invoices) &&
    isArr(items) &&
    isObj(itemsMeta) &&
    isArr(statuses) &&
    isValidActiveItem
  );
};

/**
 * @description Init state
 * @param initialState
 * @returns {*}
 */
const initState = (initialState) => {
  if (!isValid(initialState)) {
    throw Error('Invalid initial invoices state');
  }

  const { activeItem, items, itemsMeta, statuses, activeAccount, accounts } = initialState;
  return {
    activeAccount,
    accounts,
    activeItem,
    items,
    itemsMeta,
    statuses,
  };
};

/**
 * @description Getters
 * @type {*}
 */
export const getters = {
  accounts: ({ accounts }) => accounts,
  activeAccount: ({ activeAccount }) => activeAccount,
  activeItem: ({ activeItem }) => activeItem,
  items: ({ items }) => items,
  itemsMeta: ({ itemsMeta }) => itemsMeta,
  itemsData: ({ items, itemsMeta }) => ({ items, itemsMeta }),
  statuses: ({ statuses }) => statuses,
};

/**
 * @description Handle alerts
 * @param data
 * @param alertType
 */
const handleAlerts = (data, alertType = 'error') => (
  store.dispatch('alerts/set', {
    data,
    alertType
  })
);

const actions = {
  update: ({ commit, state }, payment) => (
    invoicesApi.updateItem(payment).then((res) => {
      const { activeItem } = state;
      const nextInvoice = res.data.data;
      const { invoice_number } = nextInvoice;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...nextInvoice
        }
      };

      commit('SET', nextState);

      return handleAlerts({
        data: {
          message: `Invoice "${invoice_number}" has been updated successfully.`
        }
      }, 'success');
    })
  ),
  getItem: ({ commit, state }, query) => (
    invoicesApi.getItem(query).then((res) => {
      const { activeItem } = state;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          ...res.data.data
        }
      };

      return commit('SET', nextState);
    })
  ),
  getItems: ({ commit, state }, query) => (
    invoicesApi.getItems(query).then((res) => {
      const { data, meta } = res.data;
      const nextState = {
        ...state,
        items: data,
        itemsMeta: meta
      };

      return commit('SET', nextState);
    })
  ),
  getAccount: ({ commit, state }, query) => (
    invoicesApi.getAccount(query).then((res) => {
      const nextState = {
        ...state,
        activeAccount: res.data.data
      };

      return commit('SET', nextState);
    })
  ),
  getAccounts: ({ commit, state }, query) => (
    invoicesApi.getAccounts(query).then((res) => {
      const { data } = res.data;
      const nextState = {
        ...state,
        accounts: data
      };

      return commit('SET', nextState);
    })
  ),
  getStatuses: ({ commit, state }) => (
    invoicesApi.getStatuses().then((res) => {
      const nextStatuses = _.sortBy(res.data.data, status => status.name.toLowerCase());
      const nextState = {
        ...state,
        statuses: nextStatuses
      };

      return commit('SET', nextState);
    })
  ),
  setActiveItem: ({ commit, state }, payment) => {
    const nextState = {
      ...state,
      activeItem: {
        ...state.activeItem,
        ...payment
      }
    };

    return commit('SET', nextState);
  },
  updateDiscount: (context, query) => (
    invoicesApi.updateDiscount(query)
  ),
  getActivityLogs: ({ commit, state }, query) => (
    invoicesApi.getActivityLogs(query).then((res) => {
      const { activeItem } = state;
      const { data, meta } = res.data;

      const nextState = {
        ...state,
        activeItem: {
          ...activeItem,
          log: {
            items: data,
            meta
          }
        }
      };

      return commit('SET', nextState);
    })
  ),
  generatePdf: (context, query) => (
    invoicesApi.generatePdf(query).then((res) => {
      const content = res.headers['content-type'];
      return download(res.data, query.invoice_number, content);
    })
  ),
  downloadInvoice: (context, query) => (
    invoicesApi.downloadInvoice(query).then((res) => {
      const content = res.headers['content-type'];
      const contentDisposition = res.headers['content-disposition'];
      const fileNameDetails = contentDisposition.match(/filename=.+\.[a-z]+/)[0];
      const fileName = fileNameDetails.substr(fileNameDetails.indexOf('=') + 1);
      const hash = fileName.substring(fileName.indexOf('_'), fileName.lastIndexOf('.'));
      const name = fileName.replace(hash, '');

      return download(res.data, name, content);
    })
  ),
  downloadPublicInvoice: (context, query) => (
    invoicesApi.downloadPublicInvoice(query).then((res) => {
      const content = res.headers['content-type'];
      const contentDisposition = res.headers['content-disposition'];
      const fileNameDetails = contentDisposition.match(/filename=.+\.[a-z]+/)[0];
      const fileName = fileNameDetails.substr(fileNameDetails.indexOf('=') + 1);
      const hash = fileName.substring(fileName.indexOf('_'), fileName.lastIndexOf('.'));
      const name = fileName.replace(hash, '');

      return download(res.data, name, content);
    })
  ),
  reset: ({ commit }) => (
    commit('SET', initialState.invoices)
  ),
  set: ({ commit }, invoices) => {
    commit('SET', invoices);
  }
};

const mutations = {
  SET(state, invoices) {
    /* eslint-disable no-param-reassign */
    state.activeAccount = invoices.activeAccount;
    state.accounts = invoices.accounts;
    state.activeItem = invoices.activeItem;
    state.items = invoices.items;
    state.itemsMeta = invoices.itemsMeta;
    state.statuses = invoices.statuses;
  }
};

export default initialState => ({
  namespaced: true,
  state: initState(initialState),
  getters,
  actions,
  mutations,
});
