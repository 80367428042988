<template>
  <div data-component-group="layout" data-component="section-header">
    <div class="component-header">
      <h2>{{ title }}</h2>

      <v-btn v-if="hasTrigger" class="trigger elevation-0" @click="toggle">
        {{ triggerLabel }}
      </v-btn>
    </div>

    <p v-if="hasSubtitle" class="subtitle">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        required: true,
      },
      subtitle: {
        type: String,
        default: '',
      },
      hasTrigger: {
        type: Boolean,
        default: true,
      },
      triggerLabel: {
        type: String,
        default() {
          return this.$t('ok');
        },
      },
    },
    computed: {
      hasSubtitle() {
        return this.subtitle !== '';
      },
    },
    methods: {
      toggle() {
        this.$emit('toggle');
      },
    },
  };
</script>

<style lang="scss" scoped>
@import '../../assets/styles/theme';

[data-component-group='layout'][data-component='section-header'] {
  background-color: map-deep-get($colors, shades, 'white');
  padding: 0 0 $spacing-md 0;

  > .component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .v-btn {
    margin: 0;
    height: 30px;
    min-height: 30px;

    &:before {
      background-color: map-deep-get($colors, grey, base);
    }
  }
}
</style>
