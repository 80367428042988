import axios from 'axios';
// eslint-disable-next-line import/no-cycle
import { apiUrl } from './index';

export default {
  getThread(options = {}) {
    const { id, country } = options;

    return axios({
      method: 'get',
      url: `${apiUrl}/pepper/${country}/threads/${id}`,
    });
  },
  getThreads(options = {}) {
    const { country, search = '', limit = 0 } = options;
    const params = {
      country,
    };

    if (search !== '') {
      params.search = search;
    }

    if (limit > 0) {
      params.limit = limit;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/pepper/${country}/threads`,
      params,
    });
  },
  getMerchants(params) {
    // TODO update components query and remove on refactor
    if (!!params.country) {
      params.countries = [params.country];
      delete params.country;
    }

    return axios({
      method: 'get',
      url: `${apiUrl}/pepper/merchants`,
      params,
    });
  },
};
